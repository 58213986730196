// author Jim
// 倒计时组建
const countDownFun = function(time) {
    if (time >= 0) {
        var secondTime = 0 //秒
        var minuteTime = 0; // 分
        var hourTime = 0; // 小时
        //var today = 0 //天
        // 全部剩余多少秒
        var seconds = Math.ceil(time / 1000)

        hourTime = Math.floor(seconds / 3600)
        //天数
        //today = Math.floor(hourTime / 24)
        //小时
        hourTime = Math.floor(hourTime % 24) < 10 ? '0' + Math.floor(hourTime % 24) : Math.floor(hourTime % 24)
        // 分
        minuteTime = Math.floor(seconds / 60 % 60) < 10 ? '0' + Math.floor(seconds / 60 % 60) : Math.floor(seconds / 60 % 60)
        //秒
        secondTime = Math.floor(seconds % 60) < 10 ? '0' + Math.floor(seconds % 60) : Math.floor(seconds % 60)

        return hourTime + ':' + minuteTime + ':' + secondTime
    } else {
        return "00:00:00";
    }
}

const initCountdown = function(allTime, callback) {
    let time = "00:00:00"
    let Interval = ''
    let passTime = 0
    if (allTime > 0) {
        time = countDownFun(allTime - passTime); //首次
        //定时器
        Interval = setInterval(function () {
            passTime = passTime + 1000
            if (allTime - passTime <= 0) {
                clearInterval(Interval);
                time = "00:00:00";
            } else {
                time = countDownFun(allTime - passTime);
            }
            callback(time)
        }, 1000);
    } else {
        callback(time)
    }
}

export default {
    initCountdown : initCountdown
}